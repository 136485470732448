import React, { useState, useEffect, useContext } from 'react';
import { post } from '../api/requests/main';
import { Context } from '../context/store';
import { convertToObjectArray } from '../helpers/general.js'
import { toast } from 'react-toastify';

const today = new Date();
const futureDate = new Date();
futureDate.setDate(today.getDate() + 30);

const HotelRoomPriceUpdater = () => {
    const [mainState, setMainState] = useContext(Context);
    const [startDate, setStartDate] = useState(today.toISOString().slice(0, 10));
    const [endDate, setEndDate] = useState(futureDate.toISOString().slice(0, 10));
    const [rooms, setRooms] = useState([]);
    const [bulkStartDate, setBulkStartDate] = useState('');
    const [bulkEndDate, setBulkEndDate] = useState('');
    const [roomId, setRoomId] = useState(false);
    const [roomsCount, setRoomCount] = useState([]);
    const [bulkPrice, setBulkPrice] = useState('');
    const [isClosed, setIsClosed] = useState(false);

    function getYearRange(startDate, endDate) {
        const startYear = new Date(startDate).getFullYear();
        const endYear = new Date(endDate).getFullYear();
        if (startYear === endYear) {
            return startYear;
        } else {
            return `${startYear}-${endYear}`;
        }
    }

    const rmYear = (dateString) => {
        const date = new Date(dateString);
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const dayOfWeek = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate();
        const monthName = months[date.getMonth()];

        return { dayOfWeek, dayOfMonth, monthName };
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getDatesBetween = (startDate, endDate) => {
        const dates = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            dates.push(formatDate(new Date(currentDate)));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (startDate && !isNaN(new Date(startDate))) {
            let currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() + 30);
            setEndDate(currentDate.toISOString().slice(0, 10));
        } else {
            setEndDate("");
        }
    }, [startDate]);

    useEffect(() => {
        setRoomCount([])
        fetchData();
    }, [startDate, endDate, mainState.selected_Property]);

    const fetchData = async () => {
        try {
            const response = await post('rooms/get-price', {
                property: mainState.selected_Property,
                start: startDate,
                end: endDate
            });
            setRoomCount(convertToObjectArray(response.data.rooms));
            setRooms(response.data.data);
        } catch (error) {
            console.error('Error fetching prices:', error);
        }
    };

    const updatePrice = async (room, newPrice, date) => {
        try {
            setBulkStartDate(date);
            setBulkEndDate(date);
            setRoomId(room);
            setBulkPrice(newPrice?.amount);
            setIsClosed(newPrice?.is_closed);
        } catch (error) {
            console.error(`Error updating price for ${room}:`, error);
        }
    };

    const bulkUpdatePrices = async () => {
        try {
            setMainState({ type: "loader", payload: true });
            const response = await post('rooms/bulk-update-price', {
                startDate: bulkStartDate,
                endDate: bulkEndDate,
                property: mainState.selected_Property,
                room: roomId,
                price: bulkPrice,
                is_closed: isClosed ? true : false
            });
            if (response.status === 201) {
                fetchData();
                toast('Price Updated!');
            }
        } catch (error) {
            console.error('Error in bulk update:', error);
        } finally {
            setRoomId(false);
            setMainState({ type: "loader", payload: false });
        }
    };

    function blockRoom(input) {
        setIsClosed(input.checked);
    }

    function rm(arr, roomId) {
        let selectedRoom = arr.find(obj => obj.room_id === roomId);
        return selectedRoom ? selectedRoom : undefined;
    }

    return (
        <div>
            <div className='container'>
                <div className='flex'>
                    <h2>Hostel Room Price Update</h2>
                    <div className='flex'>
                        <label className='tl'>
                            <span>From: </span>
                            <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </label>
                        <label className='tl'><span> To:</span>
                            <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </label>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <table className='priceTable'>
                    <thead>
                        <tr>
                            <th className='thead'>
                                <th className='thead'> {getYearRange(startDate, endDate)} </th>
                            </th>
                            {getDatesBetween(new Date(startDate), new Date(endDate)).map((date) => (
                                <th key={date} className='datPrice'>
                                    <span>{rmYear(date).dayOfWeek} </span>
                                    <p>{rmYear(date).dayOfMonth} </p>
                                    <span>{rmYear(date).monthName} </span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {roomsCount?.map((room, roomIndex) => (
                            <tr key={roomIndex}>
                                <td className='thead'> {room.name} : </td>
                                {getDatesBetween(new Date(startDate), new Date(endDate)).map((date) => (
                                    <td
                                        className={rm(rooms, room.id)?.records[date] && !rm(rooms, room.id)?.records[date]?.is_closed ? 'datPrice' : 'datPrice empty'}
                                        key={roomIndex + '-' + date}
                                        onClick={() => updatePrice(room.id, rm(rooms, room.id)?.records[date], date)}
                                    >
                                        {rm(rooms, room.id)?.records[date]?.amount || '***'}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {roomId && (
                <div className="popup ">
                    <div className="child">
                        <header className='flex'>
                            <h4>Bulk Update Prices <small>Room </small> </h4>
                            <span onClick={() => setRoomId(false)}><i className="fa-solid fa-xmark"></i></span>
                        </header>
                        <section>
                            <div className='flex'>
                                <label>Start Date:
                                    <input type="date" value={bulkStartDate} onChange={(e) => setBulkStartDate(e.target.value)} />
                                </label>
                                <label>End Date:
                                    <input type="date" value={bulkEndDate} onChange={(e) => setBulkEndDate(e.target.value)} />
                                </label>
                            </div>
                            <div>
                                <label>Price: <label className='box'> <input type='checkbox' checked={isClosed} onChange={(e) => blockRoom(e.target)} />  Close Room </label>
                                    <input type="number" className="form-control" value={bulkPrice} onChange={(e) => setBulkPrice(e.target.value)} />
                                </label>
                            </div>
                        </section>
                        <footer>
                            <button className='btn btn-primary' disabled={mainState.loader ? true : false} onClick={bulkUpdatePrices}>Update</button>
                        </footer>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HotelRoomPriceUpdater;
