import countries from '../pages/sub-componments/countries';

export function country(key) {
    if (!key) {
        return 'Unknown Country';
    }

    const foundCountry = countries.find(obj => obj.value.toLocaleLowerCase() === key.toLocaleLowerCase());
    return foundCountry ? foundCountry.label : 'Unknown Country';
}
