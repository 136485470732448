import { useState, useRef, useContext } from 'react';
import Select from 'react-select'
import { Context } from '../../context/store';
import { useNavigate } from "react-router-dom";
import countries from '../sub-componments/countries';
import { post } from '../../api/requests/main';
import { toast } from 'react-toastify';


function NewGuest(prop) {
    const navigate = useNavigate();
    const [phoneCheck, setPhoneCheck] = useState(true)
    const [mainState, setMainState] = useContext(Context)
    const formRef = useRef("")

    const [valid, setValid] = useState('')

    const submit = async () => {
        if (!formRef.current.name.value ||
            !formRef.current.phone.value ||
            !formRef.current.lastname.value ||
            !formRef.current.nationality.value) {
            toast.error("Please Fill All Fields!")
            return
        }
        setPhoneCheck(true)
        setMainState({ type: 'loader', payload: true })
        try {
            const response = await post('new-guests', formRef.current)
            if (response.status === 201 || response.status === 200) {
                navigate('/table');
                if (prop.setNewGuest) {
                    prop.setNewGuest(response.data.user)
                }
                toast(`Guest Saved!`)
            } else {
                toast.error(`Something went wrong, please try again!`)
            }
        } catch (error) {
        } finally {
            setMainState({ type: 'loader', payload: false })
        }
    }

    const validPhone = async (a) => {
        if (a.target.value.length > 8) {
            setPhoneCheck(true)
            setValid(".....")
            try {
                const response = await post('check-phone-guest', {property: mainState.selected_Property, "phone": a.target.value });
                if (response.status === 200) {
                    if (response.data.data.length === 0) {
                        setPhoneCheck(false)
                        setValid(false)
                    } else {
                        setPhoneCheck(true)
                        setValid(response.data.data)
                    }
                }
            } catch (error) {
            }
        } else {
            setPhoneCheck(true)
            setValid("")
        }
    };
    return (
        <>
            <form className='container new-guest' ref={formRef} >
                <div style={{ maxWidth: '400px', display: "grid", margin: "10px auto" }}>
                    <input name='property' type='hidden' value={mainState.selected_Property} />
                    <label>Nationality <Select name='nationality' required options={countries} defaultValue={countries[0]} /> </label>
                    <label className='plus'>Phone
                        <input className='form-control' name='phone' type='number' onWheel={(e) => e.target.blur()} onChange={validPhone} />
                        {!valid ?
                            <span className='check new'>new</span> :
                            <span className='check Old'>{valid[0].name ? valid[0].name : '' + " " + valid[0].lastname ? valid[0].lastname : ''}</span>
                        }
                    </label>
                    <label>Name <input className='form-control' name='name' type="text" required /> </label>
                    <label>Last Name <input className='form-control' name='lastname' type="text" required /> </label>
                    <button type='button' className='btn btn-primary  main mt-4' disabled={phoneCheck} onClick={submit}> Save </button>
                </div>
            </form>
        </>
    );
}

export default NewGuest;
