import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

function PasswordResetConfirm() {
    const { uidb64, token } = useParams();
    const [password, setPassword] = useState('');
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();
    const RESET_CONFIRM_URL = process.env.REACT_APP_BASE_URL + `password-reset-confirm/${uidb64}/${token}/`;

    const validatePassword = (password) => {
        const regex = /^(?=.*\d).{8,}$/;
        return regex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validatePassword(password)) {
            toast('Password must be at least 8 characters long and contain at least one number.');
            return;
        }

        setDisable(true);
        try {
            await axios.post(RESET_CONFIRM_URL, { password });
            toast('Password has been reset successfully.');
            navigate('/login', { replace: true });
        } catch (err) {
            toast('Link Expired');
            setDisable(false);
        }
    };

    return (
        <div className='login--container'>
            <form onSubmit={handleSubmit}>
                <div className='card small mt5'>
                    <h4>Set New Password</h4>
                    <br />
                    <label>New Password: 
                        <input 
                            className='form-control' 
                            type='password' 
                            onChange={(e) => setPassword(e.target.value)} 
                            value={password} 
                        /> 
                    </label>
                    <button disabled={disable}>Reset Password</button>
                </div>
            </form>
        </div>
    );
}

export default PasswordResetConfirm;
