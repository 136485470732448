import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function PasswordResetRequest() {
    const [email, setEmail] = useState('');
    const [hideBtn, setHideBtn] = useState(false);
    const [disable, setDisable] = useState(false);
    const RESET_REQUEST_URL = process.env.REACT_APP_BASE_URL + 'password-reset/';

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true);
        try {
            const response = await axios.post(RESET_REQUEST_URL, { email });
            if (response.status == 200) {
                setHideBtn(true)
            }
            toast(response.data.message);
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                toast(err.response.data.message);
            } else {
                toast('Error sending password reset link');
            }
            setDisable(false);
        }
    };

    return (
        <div className='login--container'>
            <form onSubmit={handleSubmit}>
                <div className='card small mt5'>
                    <h4>Reset Password</h4>
                    <br />
                    <label>Email: 
                        <input 
                            className='form-control' 
                            type='email' 
                            onChange={(e) => setEmail(e.target.value)} 
                            value={email} 
                        /> 
                    </label>
                    {hideBtn ? <></>: <button disabled={disable}>Send Reset Link</button> }
                </div>
            </form>
        </div>
    );
}

export default PasswordResetRequest;
