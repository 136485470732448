import React, { useState, useEffect } from "react";

function CollectVCard({
    setStartCollect,
    CollectRef,
    collect,
    pid,
    paymentMethod,
    mainState
}) {
    const [description, setDescription] = useState("");
    const [file, setFile] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files.length > 0 ? e.target.files[0] : null);
    };

    useEffect(() => {
        if (description.trim() || file) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [description, file]);


    async function handleSave(a) {
        var answer = window.confirm("are you sure that you want to collect the V-Card?");

        if (answer === true) {
            collect()
        }
    }


    return (
        <div className="popup ">
            <div className="card child dark">
                <header>
                    <h4> Collect V-Card </h4>
                    <span onClick={() => { setStartCollect(false); }} >
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </header>
                <section>
                    <form ref={CollectRef}>
                        <label>
                            Attachment
                            <input type="file" name="image" className="form-control" onChange={handleFileChange} />
                        </label>
                        Description:
                        <textarea name="description" className="form-control" placeholder="Payment Description" value={description} onChange={handleDescriptionChange} ></textarea>
                        <input name="pid" type="hidden" defaultValue={pid} />
                        <input name="property" type="hidden" defaultValue={mainState.selected_Property} />
                        <input name="paymentMethod" type="hidden" defaultValue={paymentMethod} />
                    </form>
                </section>

                <footer className="actions">
                    <button className="btn btn-primary main" onClick={handleSave} disabled={isButtonDisabled} >
                        Collect
                    </button>
                </footer>
            </div>
        </div>
    );
}

export default CollectVCard;
