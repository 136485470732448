import React, { useState, useRef, useEffect, useReducer, useContext } from 'react'
import { post } from '../../api/requests/main';
// import ReactHtmlParser from 'html-react-parser';
import { ExtraCharge } from '../sub-componments/paymentMethod';
import { Context } from '../../context/store';


function Stripe(p) {
    const [mainState, setMainState] = useContext(Context);
    const [amount, setAmount] = useState('')
    const [data, setData] = useState(false);
    const [type, setType] = useState(p.type);
    const [svgImg, setSvgImg] = useState(false);
    const initStripe = async () => {
        setMainState({ type: 'loader', payload: true })
        try {
            const response = await post('online/stripe/create-checkout-session', {
                "price": Number(amount) * 100,
                "uid": p.uid,
                "gid": p.gid,
                property: mainState.selected_Property,
                "type": type,
                "bid": p.bid
            });
            if (response.status === 201 || response.status === 200) {
                setData(response)
                // const parsedHtml = ReactHtmlParser();
                setSvgImg(response.data.qr)
            }
        } catch (error) {
        } finally {
            setMainState({ type: 'loader', payload: false })
        }

    }
    return (
        <>

            {data ? (
                <>
                    <div className='qr-placeholder'>
                        <p className='alert alert-success'> Payment have been successfully Generated ({amount} AED) for  </p>
                        <p> open the link form <a href={data.data.res.url} target='_blank'> here </a> or scan the QR below  </p>
                        {svgImg && <div dangerouslySetInnerHTML={{ __html: svgImg }}></div>}
                    </div>
                </>
            ) : (<>
                Amount:
                <input type='number' onWheel={(e) => e.target.blur()} name='amount' defaultValue={amount} onChange={(a) => { setAmount(a.target.value); }} className='form-control' />
                {p.type === 'booking' ?
                    <></> :
                    <>
                        Service:
                        <select name='name' className='form-select' onChange={(s) => { setType(s.target.value) }} >
                            <ExtraCharge />
                        </select>
                        <br />
                    </>
                }
                <footer className='actions'>
                    {/* <button className='btn main btn-primary ' onClick={initStripe} disabled={amount === '0' || amount === '' || amount === null || amount === undefined ? true : false} >Generate Link</button> */}
                </footer>
            </>)}


        </>
    )
}

export default Stripe