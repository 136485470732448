import { axiosPrivate } from '../axios';

// const GET_MONTH = process.env.REACT_APP_BASE_URL + 'calender/get-month';
// const GET_CASH = process.env.REACT_APP_BASE_URL + 'current-cash';
// const GET_VCARDS = process.env.REACT_APP_BASE_URL + 'vcard/list';
// const TRANS_VCARDS = process.env.REACT_APP_BASE_URL + 'vcard/ship-to-online';
// const GET_EXPENSES = process.env.REACT_APP_BASE_URL + 'expenses/expenses-list';
// const GET_CHATS = process.env.REACT_APP_BASE_URL + 'whatsapp/list-chat';
// const POST_MESSAGE = process.env.REACT_APP_BASE_URL + 'whatsapp/send-msg';

// export async function getDates(date) {
//     try {
//         const data = await axiosPrivate.post('table/get-dates', date, { headers: { 'Content-Type': 'application/json' } });
//         return data;
//     } catch (error) {
//         return error;
//     }
// }
// export async function getMonth(role) {
//     try {
//         const data = await axiosPrivate.post(GET_MONTH, role, { headers: { 'Content-Type': 'application/json' } });
//         return data;
//     } catch (error) {
//         return error;
//     }
// }
// export async function getCash(role) {
//     try {
//         const data = await axiosPrivate.post(GET_CASH, role, { headers: { 'Content-Type': 'application/json' } });
//         return data;
//     } catch (error) {
//         return error;
//     }
// }

// export async function getExpenses(role) {
//     try {
//         const data = await axiosPrivate.post(GET_EXPENSES, role, { headers: { 'Content-Type': 'application/json' } });
//         return data;
//     } catch (error) {
//         return error;
//     }
// }

// export async function listVcards(role) {
//     try {
//         const data = await axiosPrivate.post(GET_VCARDS, role, { headers: { 'Content-Type': 'application/json' } });
//         return data;
//     } catch (error) {
//         return error;
//     }
// }

// export async function transferVcard(payload) {
//     try {
//         const data = await axiosPrivate.post(TRANS_VCARDS, payload, { headers: { 'Content-Type': 'application/json' } });
//         return data;
//     } catch (error) {
//         return error;
//     }
// }

export async function post(url, role) {
    try {
        const data = await axiosPrivate.post(process.env.REACT_APP_BASE_URL + url, role, { headers: { 'Content-Type': 'application/json' } });
        return data;
    } catch (error) {
        return error;
    }
}
export async function getPrivate(url, role) {
    try {
        const data = await axiosPrivate.get(process.env.REACT_APP_BASE_URL + url, role, { headers: { 'Content-Type': 'application/json' } });
        return data;
    } catch (error) {
        return error;
    }
}

export async function postFormData(url, role) {
    try {
        const data = await axiosPrivate.post(process.env.REACT_APP_BASE_URL + url, role, { headers: { "Content-Type": "multipart/form-data" } });
        return data;
    } catch (error) {
        return error;
    }
}

export async function get(url) {
    try {
        const data = await axiosPrivate.get(process.env.REACT_APP_BASE_URL + url);
        return data;
    } catch (error) {
        return error;
    }
}


// export async function listChats(role) {
//     try {
//         const data = await axiosPrivate.get(GET_CHATS, role, { headers: { 'Content-Type': 'application/json' } });
//         return data;
//     } catch (error) {
//         return error;
//     }
// }
// export async function getSingleChat(phoneId) {
//     try {
//         const data = await axiosPrivate.get(GET_SINGLE_CHAT + "/" + phoneId, { headers: { 'Content-Type': 'application/json' } });
//         return data;
//     } catch (error) {
//         return error;
//     }
// }
// export async function sendMessage(message) {
//     try {
//         const data = await axiosPrivate.post(POST_MESSAGE, message, { headers: { 'Content-Type': 'application/json' } });
//         return data;
//     } catch (error) {
//         return error;
//     }
// }