import { useState, useRef, useContext } from 'react';
import AsyncSelect from 'react-select/async';
import { post } from '../../api/requests/main';
import { Context } from '../../context/store';

import CheckIn from './checkin';

function NewPassport() {
    const formRef = useRef("")
    const formPassRef = useRef("");
    const [mainState, setMainState] = useContext(Context);

    const [cacheOptions] = useState(true);
    const [defaultOptions] = useState(true);
    const [selectedGuest, setSelectedGuest] = useState(null);
    const [selectedGuestP, setSelectedGuestP] = useState(null);



    const filterColors = (inputValue) => {
        const convertedData = inputValue.map(item => {
            const fullName = `${item.name.trim()} ${item.lastname.trim()}`;
            return {
                value: item.id,
                label: fullName
            };
        });
        return convertedData;
    };

    const promiseOptions = async (inputValue) => {
        return new Promise(async (resolve) => {
            if (inputValue.length > 2) {
                try {
                    const response = await post('search-unchecked-in-users', { "name": inputValue });
                    if (response.status === 200) {
                        resolve(filterColors(response.data.data));
                    }
                } catch (error) {
                    // handle error
                }
            }
        });
    }

    const getNewUser = async (a) => {
        // setSelectedGuest(undefined)
        try {
            const response = await post('get-user-by-id', { property: mainState.selected_Property,"id": a.value });
            if (response.status === 200) {
                setSelectedGuest(response.data.data.user)
                setSelectedGuestP(response.data.data.passport)
            }
        } catch (error) {
        }
    };



    return (
        <>
            <form className='container nop' ref={formRef}>
                <div className='flex'>
                    <label>Name
                        <AsyncSelect
                            onChange={getNewUser}
                            cacheOptions={cacheOptions}
                            defaultOptions={defaultOptions}
                            loadOptions={promiseOptions}
                        />
                    </label>
                </div>
                {selectedGuest === undefined ? <div className='card dark flex'>Loading ...</div> : ""}
                {selectedGuest ?
                    <div className='card'>
                        Name: {selectedGuest.name} {selectedGuest.lastname} <span className='p-2'></span>
                        Phone: {selectedGuest.phone}

                    </div>
                    : <></>
                }

                {/* <button type='button' className='btn btn-primary main' disabled={phoneCheck} onClick={submit}> save </button> */}
            </form>
            <form className='container' ref={formPassRef}>
                {selectedGuestP === undefined || selectedGuestP === null ? <></>
                    : <>
                        <div className='card'>
                            {selectedGuest.id !== "" ? <>
                                <CheckIn gid={selectedGuest.id} />
                            </> : <></>}
                        </div>
                    </>
                }
            </form>

        </>
    );
}

export default NewPassport;