import React, { useContext, useEffect, useState } from 'react'
import { post } from '../../api/requests/main';
import { Context } from '../../context/store';
import { formatDate } from '../../helpers/dateFormat';


function WaitListWebsite() {
    const [mainState, setMainState] = useContext(Context);
    const [data, setData] = useState([]);
    useEffect(() => {
        getcalender()
    }, [mainState.selected_Property])

    const getcalender = async () => {
        setMainState({ type: 'loader', payload: true })
        try {
            const response = await post('prebook/list', {property: mainState.selected_Property,});
            if (response.status === 200) {
                setData(response.data)
            }

        } catch (error) {
        } finally { setMainState({ type: 'loader', payload: false }) }
    };


    return (
        <>

            <div className='container center'>
                <br />
                <br />
            </div>
            <div className='container'>
                <div className='row'>
                    <table className='table custom table-white vc'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Booking Date</th>
                                <th>Amount</th>
                                <th>Room Number</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data ?
                                data.map((a, id) => {
                                    return <tr id={a.bid} key={id} >
                                        <td >{a.guest.name} </td>
                                        <td >{a.guest.email} </td>
                                        <td >{a.guest.phone} </td>
                                        <td >{a.listDays[0].date} <small>({a.listDays.length})</small> </td>
                                        <td>{a.amount}</td>
                                        <td> {a.roomNumber} </td>
                                        <td>
                                            {formatDate(a.created)}
                                        </td>
                                    </tr>
                                }) : <tr>
                                    <td colspan="6">
                                        NO RECORDS TO DISPLAY
                                    </td>
                                </tr>}
                        </tbody >
                    </table>
                </div>
            </div>

        </>
    )
}

export default WaitListWebsite;