import React, { useEffect, useState, useContext } from "react";
import { post } from "../../api/requests/main";
import { formatDate } from "../../helpers/dateFormat";
import { Context } from '../../context/store';

import SingleChat from "./SingleCHat";


import { useLocation } from 'react-router-dom';


const Chat = () => {
  const [mainState, setMainState] = useContext(Context);
  const [chatList, setChatList] = useState([])
  const [userData, setUserData] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [performance, setPerformance] = useState(false)
  const [userName, setUserName] = useState(false)
  const [isWhatsappDisabled, setIsWhatsappDisabled] = useState(false)
  const [noData, setNoData] = useState(false)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const phone = searchParams.get('phone');
  const [phoneId, setPhoneId] = useState(phone || 0)

  function removeDuplicatePhones(data) {
    const uniquePhones = data.reduce((unique, item) => {
      if (!unique[item.phone]) {
        unique[item.phone] = item;
      }
      return unique;
    }, {});
    let array = Object.values(uniquePhones);
    return array.sort((a, b) => new Date(b.time) - new Date(a.time));
  }

  const getAllChats = async () => {

    try {
      const response = await post('whatsapp/list-chat', { property: mainState.selected_Property });
      if (response.status === 202) {
        setIsWhatsappDisabled(true)
        return response
      }
      if (response.status === 200) {
        if (response.data.length === 0) {
          setNoData(true)
        }
        setChatList(removeDuplicatePhones(response.data));
        return response
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (mainState?.selected_Property) {
      setIsLoading(true);
      getAllChats();
    }
  }, [mainState?.selected_Property])


  useEffect(() => {
    if (chatList.length) {
      getPerformance()
    }
  }, [chatList])

  useEffect(() => {
    if (phoneId) {
      handleSingleChatDetails()
    }
  }, [phoneId, setPhoneId])

  const handleSingleChatDetails = async () => {
    try {
      const response = await post('whatsapp/view-chat', { property: mainState.selected_Property, phone: phoneId });
      if (response.status === 200) {
        setUserName(response.data.user);
        setUserData(response.data.chat);
      }
    } catch (error) {
      setUserData(false)
    }

  }
  const getPerformance = async () => {
    try {
      const response = await post('whatsapp/return-performance', { property: mainState.selected_Property });
      if (response.status === 200) {
        setPerformance(response.data)
      }
    } catch (error) {
      setUserData(false)
    }

  }

  if (isLoading) {
    return <>
      <div className="chat-body">
        <div className="chat-window none">
          Loading ...
        </div>
      </div>
    </>
  }

  if (isWhatsappDisabled) {
    return <>
      <div className="chat-body">
        <div className="chat-window none">
          WhatsaApp is not enabled
        </div>
      </div>
    </>
  }
  if (noData) {
    return <>
      <div className="chat-body">
        <div className="chat-window none">
          No messages found
        </div>
      </div>
    </>
  }

  const convertSeconds = (seconds) => {
    try {
      seconds = parseFloat(seconds);
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = Math.floor(seconds % 60);
      return `${hours}h ${minutes}m ${secs}s`;
    } catch (e) {
      return "0s";
    }
  }

  return (
    <>
      <div className="chat-body">
        <div className="chat-performance">


          {performance &&
            <div className="performance-metrics flex">
              <div className="metric">
                <span>Average Reply Time:</span>
                <b 
                        style={{
                          color: performance?.avg_reply_time_seconds > 1000
                            ? 'red'
                            : performance?.avg_reply_time_seconds > 500
                            ? 'darkred'
                            : performance?.avg_reply_time_seconds > 200
                            ? 'green'
                            : 'black'
                        }}
                
                >
                  {convertSeconds(performance?.avg_reply_time_seconds)}{" "}
                </b>
              </div>
              <div className="metric">
                <span>Total Unread Messages:</span>
                <b>{performance.total_unread_messages}</b>
              </div>
            </div>
          }

        </div>
        <div className="chat-window">

          <div className="conversations">
            {chatList.map((user, id) => {
              return (
                <div key={id} className={user.phone === phoneId ? "chat-details active" : "chat-details"} onClick={() => { setPhoneId(user.phone) }}>
                  <span>{user.fullname}</span>
                  <span className="time">{formatDate(user.time)}</span>
                </div>
              );
            })}
          </div>
          {userData && (
            <SingleChat phoneId={phoneId} userData={userData} setUserData={setUserData} userName={userName} />
          )}
        </div>
      </div>
    </>
  );
};

export default Chat;
