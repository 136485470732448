import React, { useContext, useEffect, useState } from 'react';
import { post } from '../api/requests/main';
import { Context } from '../context/store';

function BookingLogs() {
    const [mainState, setMainState] = useContext(Context);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [next, setNext] = useState(false);
    const [prev, setPrev] = useState(false);

    useEffect(() => {
        fetchBookingLogs(`booking-logs/list?page=${page}&property_id=${mainState.selected_Property}`);
    }, []);

    useEffect(() => {
        fetchBookingLogs(`booking-logs/list?page=${page}&property_id=${mainState.selected_Property}`);
    }, [page, mainState.selected_Property]);

    const fetchBookingLogs = async (url) => {
        setMainState({ type: 'loader', payload: true });
        try {
            const response = await post(url, {});
            if (response.status === 200) {
                setData(response.data.results);
                setNext(response.data.next);
                setPrev(response.data.previous);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
        } finally {
            setMainState({ type: 'loader', payload: false });
        }
    };
    const convertToLocalTime = (utcTime) => {
        const date = new Date(utcTime);
        return date.toLocaleString();
    };


    return (
        <>
            <div className='container'>
                <div className='row'>
                    <h2 style={{ 'padding': "40px", 'textAlign': 'center' }}>Logs</h2>
                    {data.map((a, id) => {
                        if (a.status === "update-status-local") {
                            return <div key={'booking' + id} className={'list--update modified'}>
                                <span className='status'> <b className='a' onClick={() => { setMainState({ type: "bookingId", payload: a.bid }); }}> Modified</b>  </span>
                                <div>
                                    Status change: <span className={`e_statue s ${a.prev_status}`} >{a.prev_status}</span> → <span className={`e_statue  ${a.book_status}`}>{a.book_status}</span>
                                </div>
                                <span className='date'>
                                    {a.user} - {convertToLocalTime(a.created)}
                                </span>
                            </div>
                        } else if (a.status === "new" || a.status === "modified-cm" || a.status === "new-local" || a.status === "new-cm") {
                            return <div key={'booking' + id} className='list--update new'>
                                <span className='status'> <b className='a' onClick={() => { setMainState({ type: "bookingId", payload: a.bid }); }}> New</b>  </span>
                                <div>
                                    <span className='platform'>{a.platform}</span>
                                    <span className='dates'>{a.checkin} → {a.checkout} </span>
                                    <span className='amount'>{a.amount} </span>
                                    {/* Got yah Bitch !! */}
                                    {a.status === "new-local" &&
                                        a.defaultPricePerDay &&
                                        a.pricePerDay &&
                                        !isNaN(Number(a.defaultPricePerDay)) &&
                                        !isNaN(Number(a.pricePerDay)) &&
                                        Number(a.pricePerDay) !== 0 &&
                                        Number(a.defaultPricePerDay) !== 0 &&
                                        Number(a.defaultPricePerDay) > Number(a.pricePerDay) &&
                                        Number(a.defaultPricePerDay) !== Number(a.pricePerDay) && (
                                            <span className='discount'>
                                                {Number(a.defaultPricePerDay - a.pricePerDay).toFixed(2)}
                                            </span>
                                        )}
                                </div>
                                <span className='date'>
                                    {a.user} - {convertToLocalTime(a.created)}
                                </span>
                            </div>
                        } else if (a.status === "update-booking-bed") {
                            return <div key={'booking' + id} className='list--update modified'>
                                <span className='status'> <b className='a' onClick={() => { setMainState({ type: "bookingId", payload: a.bid }); }}> Modified</b>  </span>
                                <div>
                                    <span className='platform'> Bed Change: </span>
                                    <span className='bed'>{a.prev_bed}</span> → <span className='bed n'>{a.book_bed}</span>
                                </div>
                                <span className='date'>
                                    {a.user} - {convertToLocalTime(a.created)}
                                </span>
                            </div>
                        } else if (a.status === "cancelled-local" || a.status === "cancelled-cm") {
                            return <div key={'booking' + id} className='list--update cancelled'>
                                <span className='status'> <b className='a' onClick={() => { setMainState({ type: "bookingId", payload: a.bid }); }}> Cancelled</b>  </span>
                                <div>
                                    <span className='platform'>{a.platform}</span>
                                    <span className='dates'>{a.checkin} → {a.checkout} </span>
                                    <span className='amount'>{a.amount} </span>
                                </div>
                                <span className='date'>
                                    {a.user} - {convertToLocalTime(a.created)}
                                </span>
                            </div>
                        } else if (a.status === "update-booking-amount") {
                            return <div key={'booking' + id} className={'list--update ' + a.status}>
                                <span className='status'> <b className='a' onClick={() => { setMainState({ type: "bookingId", payload: a.bid }); }}> Modified</b>  </span>
                                <div>
                                    <span className='platform'>Price Update</span>
                                    <span
                                        className={`price ${Number(a.defaultPricePerDay) > Number(a.pricePerDay) ? "red" : " "}`}
                                    >
                                        {Number(a.defaultPricePerDay).toFixed(2)} → {Number(a.pricePerDay).toFixed(2)} </span>
                                </div>
                                <span className='date'>
                                    {a.user} - {convertToLocalTime(a.created)}
                                </span>
                            </div>
                        } else if (a.status === "update-booking-day") {
                            return <div key={'booking' + id} className={'list--update ' + a.status}>
                                <span className='status'> <b className='a' onClick={() => { setMainState({ type: "bookingId", payload: a.bid }); }}> Modified</b>  </span>
                                <div>
                                    <span className='platform'>Day Update</span>
                                    <span className='dates'>{a.checkin} → {a.checkout} </span>

                                </div>
                                <span className='date'>
                                    {a.user} - {convertToLocalTime(a.created)}
                                </span>
                            </div>
                        } else {
                            return <div key={'booking' + id} className={'list--update ' + a.status}>
                                <span className='status'> <b className='a' onClick={() => { setMainState({ type: "bookingId", payload: a.bid }); }}> Modified</b>  </span>
                                <div>
                                    <span className='platform'>{a.platform}</span>
                                    <span className='dates'>{a.checkin} → {a.checkout} </span>
                                    <span className='amount'>{a.amount} </span>
                                </div>
                                <span className='date'>
                                    {a.user} - {convertToLocalTime(a.created)}
                                </span>
                            </div>
                        }
                    })}
                    {/* </table> */}
                    <div className="pagination">
                        <button className='btn btn-primary ml-4' onClick={() => setPage(page + 1)} disabled={!next} >Previous</button>
                        <button className='btn btn-primary ml-4' onClick={() => setPage(page - 1)} disabled={!prev} >Next</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BookingLogs;
