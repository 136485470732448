export function findRoomOrderByBed(bed, rooms) {
  // const rooms = JSON.parse(JSON.parse(localStorage.getItem('configurations')).rooms);
  let cumulativeBeds = 0;

  for (let i = 0; i < rooms.length; i++) {
    cumulativeBeds += rooms[i].beds.length;
    if (bed <= cumulativeBeds) {
      return i + 1;
    }
  }
  return -1;
}

export function getRoomById(rooms, roomId) {
  return rooms.find(room => room.room_id === roomId);
}


export default function findRange(number, rooms) {
  try {

    // const ranges = JSON.parse(JSON.parse(localStorage.getItem('configurations')).rooms);

    return rooms[number].room_name
  } catch (error) {
    console.error("Failed to parse", error);
  }

  return -1;
}


export function GetRoomsCount() {
  try {
    const ranges = JSON.parse(process.env.REACT_APP_ROOM_ORDER.replace(/'/g, '"'));

    const roomObjects = Object.entries(ranges).map(([key, value], index) => {
      return {
        name: key,
        id: index.toString(),
        capacity: value
      };
    });

    return roomObjects;
  } catch (error) {
    console.error("Failed to parse REACT_APP_ROOM_CAPACITY:", error);
    return [];
  }
}

export function getTotalCount() {
  const envVar = process.env.REACT_APP_ROOM_ORDER; // Replace MY_ENV_VAR with your actual environment variable name

  // Parse the JSON string into an object
  const values = JSON.parse(envVar);

  // Calculate the total count of all values
  let totalCount = 0;
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      totalCount += values[key];
    }
  }

  return totalCount;
}