import { useState } from 'react';


function AddNewExtraCharge({ mainState, uid, user_id, setStripePopup, setnewExtraCharge, selected_Property, ExtraChargeRef, ExtraCharge, SubMethod, saveExtraCharge, bid }) {
    const [amount, setAmount] = useState('');

    return (
        <>
            <div className="popup ">
                <div className="card child dark">
                    <header className='flex'>
                        <h4>Add new Extra Charge - <b className="Stripe" onClick={() => { setStripePopup(true); }} > Stripe </b></h4>
                        <span onClick={() => { setnewExtraCharge(false) }}><i className="fa-solid fa-xmark"></i></span>
                    </header>
                    <section>
                        <form ref={ExtraChargeRef}>
                            <input name='property' type='hidden' value={selected_Property} />

                            <div className="input-50">
                                <span>
                                    Amount:
                                    <input type="number" onWheel={(e) => e.target.blur()} onChange={(s) => { setAmount(s.target.value) }} className="form-control" name="amount" placeholder="amount" />
                                </span>
                                <input name="date" readOnly type="hidden" className="form-control" defaultValue={new Date().toISOString().substr(0, 10)} />
                            </div>
                            Service:
                            <select name="name" className="form-select">
                                <ExtraCharge />
                            </select>
                            Type:
                            <select name="type" className="form-select" defaultValue="income" readOnly >
                                {mainState?.user?.is_staff ? (
                                    <>
                                        <option value="income">Income</option>
                                        <option value="outgoing">Outgoing</option>
                                    </>
                                ) : (
                                    <>
                                        <option value="income">Income</option>
                                    </>
                                )}
                            </select>
                            Payment Account:
                            <select name="paymentMethod" className="form-select" >
                                {mainState?.user?.is_staff ? (
                                    <SubMethod selected_Property={mainState.selected_Property} />
                                ) : (
                                    <>
                                        {mainState?.user?.username && <option value={mainState.user.username}>{mainState.user.username}</option>}
                                        <option value="POS">POS</option>
                                    </>
                                )}

                            </select>
                            <label>
                                Attachment
                                <input type="file" name="image" className="form-control" />
                            </label>
                            Description:
                            <textarea
                                name="description"
                                className="form-control"
                                placeholder="Payment Description"
                            ></textarea>
                            <input name="gid_id" type="hidden" defaultValue={uid} />
                            <input name="bid_id" type="hidden" defaultValue={bid} />
                        </form>
                    </section>
                    <footer className='actions'>
                        <button disabled={!amount} className="btn btn-primary main" onClick={saveExtraCharge} > Save Payment </button>
                    </footer>
                </div>
            </div>
        </>
    )
}

export default AddNewExtraCharge;