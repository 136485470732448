import React, { useEffect, useState, useContext } from 'react'
import { country } from '../../helpers/getCountry';
import { post } from '../../api/requests/main';
import { Context } from '../../context/store';
import { maskPhone } from '../../helpers/general';


function Users() {
    const [mainState, setMainState] = useContext(Context);
    const [load, setLoad] = useState(false);
    const [userId, setUserId] = useState(false);
    const [data, setData] = useState([]);
    const [defData, setDefData] = useState([]);

    useEffect(() => {
        getcalender()
    }, [mainState.selected_Property])

    const getcalender = async (e) => {
        setLoad(true)
        try {
            const response = await post('list-users-50', { property: mainState.selected_Property });
            if (response.status === 200) {
                setLoad(false)
                setDefData(response.data.data.user);
                setData(response.data.data.user);
            }
        } catch (error) { setLoad(false) }
    }
    const viewUser = (s) => {
        setUserId(s)
    }

    const promiseOptions = async (inputValue) => {
        return new Promise(async (resolve) => {
            if (inputValue.target.value.length > 2) {
                try {
                    const response = await post('search-users', { property: mainState.selected_Property, "name": inputValue.target.value });
                    if (response.status === 200) {
                        setData(response.data.data);
                    }
                } catch (error) {
                    // handle error
                }
            }
            if (inputValue.target.value.length === 0) {
                setData(defData);
            }
        });
    }

    const closePopupUser = (s) => {
        setUserId(false)
    }

    return (
        <>
            <div className='container'>
                <from>
                    <input type='text' className='form-control' placeholder='search for users by name, phone ' onChange={promiseOptions} />
                </from>
                <div className='row' style={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}>

                    <table className="table table-dark custom">
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Phone Number</th>
                                <th>Country</th>
                                <th>Status</th>
                                <th>Email Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(a => {
                                return <tr onClick={(t) => setMainState({ type: "userId", payload: a.id })} key={a.id}>
                                    <th>{a.name} {a.lastname}</th>
                                    {/* <td>{a.phone}</td> */}
                                    <td>{mainState.user.is_staff ? a.phone : maskPhone(a.phone)}</td>
                                    <td>{country(a.nationality)}</td>
                                    <td>{a.email ? <span className='success' >Checked-in &#x2713; </span> : <></>}</td>
                                    <td> <span className={a.verified === "True" ? "success" : "red"}>{a?.email !== "False" ? a?.email : ""}</span> </td>
                                </tr>
                            })}

                        </tbody>
                    </table>
                </div>
                <div className='row'>

                </div>
            </div>
            {load ? <div className='load' > <span className='tar'></span></div> : <></>}
        </>
    )
}

export default Users