import React, { createContext, useReducer, useEffect } from 'react';

const d = new Date();

const initialState = {
    selectedDate: { m: d.getMonth() + 1, y: d.getFullYear() },
    token: '',
    loader: false,
    scroll: true,
    property: false,
    selected_Property: false,
    selectedTable: [],
    notification: { Type: null, text: null },
    user: false,
    rooms:[],
    // configurations: false,
    userId: false,
    reload: false,
    bookingId: false,
};

const reducer = (state, action) => ({ ...state, [action.type]: action.payload });

export const Context = createContext(initialState);

export const Store = ({ children }) => {
    const [state, dispatch] = useReducer(
        reducer,
        initialState,
        () => {
            const storedState = JSON.parse(localStorage.getItem('mainState')) || initialState;
            return { 
                ...storedState, 
                "bookingId":false,
                "userId":false,
                loader: false 
            };
        }
    );

    useEffect(() => {
        localStorage.setItem('mainState', JSON.stringify(state));
    }, [state]);

    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    );
};