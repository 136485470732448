import React, { useContext, useEffect, useRef, useState } from 'react'
import { post, postFormData } from '../../api/requests/main';
import { Context } from '../../context/store';
import { toast } from 'react-toastify';

import CollectVCard from '../../components/common/popups/sub/collectVcard'

import { calcAll } from '../../helpers/getTotalAmount';
function Vcards() {
    const d = new Date();
    const [mainState, setMainState] = useContext(Context);

    const [data, setData] = useState([]);
    const [startCollect, setStartCollect] = useState(false);

    const [pid, setPID] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');

    const [paymentMethods, setPaymentMethods] = useState([]);
    const CollectRef = useRef("");


    const [m, setM] = useState(mainState.selectedDate.m || d.getMonth() + 1);
    const [y, setY] = useState(mainState.selectedDate.y || d.getFullYear());


    // const 
    useEffect(() => {
        getcalender()
        setMainState({ type: 'selectedDate', payload: { 'y': Number(y), 'm': Number(m) } });
    }, [m, y, setY, setM, mainState.selected_Property])
    useEffect(() => {
        fetchPaymentMethods()

    }, [])

    const fetchPaymentMethods = async () => {

        post('accounts/list', { property: mainState.selected_Property })
            .then(response => {
                setPaymentMethods(response.data)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };



    const getcalender = async () => {
        setMainState({ type: 'loader', payload: true })

        try {
            const response = await post('vcard/list', { property: mainState.selected_Property, "year": y, "month": Number(m) });
            if (response.status === 200) {
                setData(response.data);
            }
        } catch (error) {
        } finally { setMainState({ type: 'loader', payload: false }) }
    };

    const viewUser = (s) => {
        setMainState({ type: "userId", payload: s });
    }
    async function showMod(a) {
        let mainElm = a.target.parentElement.parentElement
        setPaymentMethod(mainElm.querySelector('[name="paymentMethods"]').value)
        setPID(a.target.id)
        setStartCollect(true)
    }
    async function collect(a) {
        if (mainState.loader) { return; }
        setMainState({ type: "loader", payload: true });
        try {
            let load = new FormData();
            load.append("image", CollectRef.current.image.files[0]);
            load.append("description", CollectRef.current.description.value);
            load.append("pid", CollectRef.current.pid.value);
            load.append("property", CollectRef.current.property.value);
            load.append("paymentMethod", CollectRef.current.paymentMethod.value);

            let response = await postFormData('vcard/ship-to-online', load);
            console.log(response)
            if (response.status === 201 || response.status === 200) {
                toast("New Payment added!");
            }
        } catch (error) {
            console.log('yoyooy',error)
            
        } finally {
            console.log('zzzz')
            setStartCollect(false)
            getcalender()
            setMainState({ type: "loader", payload: false });

        }
    }


    return (
        <>
            <div className='container center'>
                <br />
                <div>
                    <h2 style={{ textAlign: 'center' }}> Uncollected Virtual Credit Card Amount <b style={{ color: "color: rgb(21 198 229);" }}>{calcAll(data).toFixed(2)}</b> </h2>
                </div>
                <br />
            </div>
            <div className='container'>
                <div className='row' style={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}>
                    <table className='table custom table-white vc'>
                        <tr>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th width="50"></th>
                        </tr>
                        {data.map((a) => {
                            return <tr id={a.id} key={a.id} >
                                <td className='click a' onClick={() => { viewUser(a.gid_id) }}  >{a.guests.name} {a.guests.lastname}</td>
                                <td>{a.amount}</td>
                                <td >{a.description}</td>
                                <td >{new Date(a.date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                <td className='flex-select'>
                                    <select className="sm form-select" name="paymentMethods" >
                                        {paymentMethods.map((method) => (
                                            method.id && method.is_active && method.is_online ? <option key={method.id} value={method.name}>{method.name}</option> : false
                                        ))}
                                    </select>
                                    <button onClick={(s) => { showMod(s) }} id={a.id} className='btn btn-primary'>Collect </button>
                                </td>
                            </tr>
                        })}
                    </table>
                </div>
            </div>
            {startCollect && <CollectVCard
                paymentMethod={paymentMethod}
                mainState={mainState}
                pid={pid}
                setStartCollect={setStartCollect}
                CollectRef={CollectRef}
                collect={collect}
            />}
        </>
    )
}

export default Vcards