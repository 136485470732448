import { useRef, useState, useContext } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import Notification from '../../components/common/noti/notification';
import { postFormData } from '../../api/requests/main';
import { Context } from '../../context/store';
import { toast } from 'react-toastify';


function CheckIn({ gid, showPop, user }) {
    const [mainState, setMainState] = useContext(Context);
    const [imageUrl, setImageUrl] = useState(null);
    const [disable, setDisable] = useState(false);
    const [sig, setSig] = useState(user?.signature);
    const formRef = useRef("");
    const sigCanvasRef = useRef({});
    const handleImageUpload = (event) => {
        const selectedFile = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            setImageUrl(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true)
        if (sigCanvasRef.current.isEmpty()) {
            alert("signature empty!")
            setDisable(false)
            return
        }
        if (formRef.current.email.value === '') { alert("email empty!"); setDisable(false); return }
        if (formRef.current.phone.value === '') { alert("phone empty!"); setDisable(false); return }
        if (!user?.passports?.image) {
            if (formRef.current.image.value === '') { alert("passport empty!"); setDisable(false); return }
        }

        try {
            const response = await postFormData('init-checkin', formRef.current);
            if (response.status === 201 || response.status === 200) {
                if (showPop) {
                    showPop(false)
                    toast('Check-in Saved!')
                }
            }
        } catch (error) {
            setDisable(false)
            console.error(error);
        }
    }

    return (
        <>
            <header className='flex'>
                <nav className='flex'>
                    <h4 className="nop"> Check-in Process </h4>
                </nav>
                <span onClick={a => { showPop(false) }}> <i className="fa-solid fa-xmark"></i> </span>

            </header>
            <section className='main-container nop'>
                <form ref={formRef} className='nop'>
                    <input name='property' type='hidden' value={mainState.selected_Property} />
                    <label>Email Address:
                        <input type="email" name="email" required="required" defaultValue={user.email ? user.email : ""} className="form-control" />
                    </label>
                    <label>Whatsapp Number
                        <span className='sm warning'>please make sure the phone number is correct </span>
                        <span className='sm info'>make sure the phone number is written with the country key</span>
                        <input type="phone" name="phone" required="required" defaultValue={user.phone ? user.phone : ""} className="form-control" />
                    </label>
                    <div className="form-group col-10 ">
                        <label >Passport
                            <input type="file" onChange={handleImageUpload} name="image" accept='image/*' className="form-control" />
                        </label>
                        {imageUrl ? <img src={imageUrl} className='sm-ps' alt="Selected Image" /> :
                            <>
                                {user?.passports?.image ?
                                    <img src={'https://alphatel.fra1.cdn.digitaloceanspaces.com/passport/' + user?.passports?.image} className='sm-ps' />
                                    :
                                    <></>
                                }
                            </>
                        }
                    </div>
                    <div className='Signature'>
                        <div>
                            My Signature
                        </div>
                        <SignatureCanvas
                            ref={sigCanvasRef}
                            onEnd={a => { setSig(sigCanvasRef.current.getTrimmedCanvas().toDataURL("image/png")) }}
                            canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }} />
                        <input type="hidden" value={sig} name="signature" />
                        <input type="hidden" value='en' name="selectedLang" />
                        <br />
                        <p onClick={a => { sigCanvasRef.current.clear() }} className="btn btn-light">
                            Clear Signature
                        </p>
                    </div>
                    <br />
                    <input type='hidden' value={gid} name='gid' />

                </form>

            </section>
            <footer>
                <button onClick={handleSubmit} disabled={disable} className="btn btn-primary main">Save</button>
            </footer>
        </>
    );
}

export default CheckIn;
