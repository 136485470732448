import React, { useContext, useEffect, useRef, useState } from 'react'
import { get, post } from '../../api/requests/main';
import { Context } from '../../context/store';
import { payment } from '../../helpers/getTotal';
import { postFormData } from '../../api/requests/main';
import Select from 'react-select'
import { formatDate } from '../../helpers/dateFormat';
import { toast } from 'react-toastify';

function Cash() {
    const d = new Date();
    const [mainState, setMainState] = useContext(Context);

    const [Expense, setExpense] = useState(false);
    const [expenseList, setExpenseList] = useState([]);
    const [savingExpense, setSavingExpense] = useState(false);
    const [data, setData] = useState([]);
    const [bedChange, setBedChange] = useState(null); // Initially null, not fetched
    const [showBedChange, setShowBedChange] = useState(false); // Controls whether to show or hide bed changes
    const [m, setM] = useState(mainState.selectedDate.m || d.getMonth() + 1);
    const [y, setY] = useState(mainState.selectedDate.y || d.getFullYear());
    const ExpenseRef = useRef("");

    useEffect(() => {
        getcalender();
        setMainState({ type: 'selectedDate', payload: { 'y': Number(y), 'm': Number(m) } });
    }, [m, y, setY, setM]);

    useEffect(() => {
        getExpenseList();
    }, [mainState.selected_Property]);

    const getExpenseList = async () => {
        setMainState({ type: 'loader', payload: true });

        try {
            const response = await post('expense/list', { property: mainState.selected_Property });
            if (response.status === 200) {
                const restructuredList = response.data.map(expense => {
                    const label = expense.split(/(?=[A-Z])/).join(' ').replace(/^[a-z]/, (m) => m.toUpperCase());
                    return { label, value: expense };
                });
                setExpenseList(restructuredList);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setMainState({ type: 'loader', payload: false });
        }
    };

    const getBedChange = async () => {
        setMainState({ type: 'loader', payload: true });

        try {
            const response = await post('user-staff-bed-change', {
                property: mainState.selected_Property,
            });
            if (response.status === 200 || response.status === 201) {
                console.log(response.data);
                setBedChange(response.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setMainState({ type: 'loader', payload: false });
        }
    };

    const getcalender = async () => {
        setMainState({ type: 'loader', payload: true });

        try {
            const response = await post('user-staff-current-cash', {
                property: mainState.selected_Property,
                user: mainState?.user?.username,
                "y": y, "m": Number(m)
            });
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setMainState({ type: 'loader', payload: false });
        }
    };

    const saveExpense = async () => {
        if (ExpenseRef.current.amount.value || ExpenseRef.current.amount.value !== "") {
            if (savingExpense) {
                return;
            }

            try {
                setSavingExpense(true);

                const response = await postFormData('payment-add', ExpenseRef.current);
                if (response.status === 201 || response.status === 200) {
                    toast('Payment added!');
                    setExpense(false);
                    getcalender();
                }
            } catch (error) {
                console.error(error);
            } finally {
                setSavingExpense(false);
            }
        } else {
            alert("Please Fill all the fields");
        }
    };

    const handleShowBedChange = () => {
        if (!showBedChange) {
            getBedChange();
        }
        setShowBedChange(!showBedChange);
    };

    return (
        <>
            <div className='container center'>
                <h5>Bed Sheets to Change</h5>

                {/* Show button only if bedChange is not yet loaded */}
                {!bedChange && (
                    <button className='btn btn-primary' onClick={handleShowBedChange}>
                        {showBedChange ? 'Hide Bed Changes' : 'Show Bed Changes'}
                    </button>
                )}

                {showBedChange && (
                    bedChange ? (
                        bedChange.map((s, index) => (
                            <span key={index}>{s} - </span>
                        ))
                    ) : (
                        <>Loading...</>
                    )
                )}
            </div>


            <div className='container center'>
                <p className='btn btn-primary' onClick={() => { setExpense(true); }}> Add Expense </p>
            </div>

            {data?.map((item, id) => (
                <div className='container card' key={id}>
                    <div className='center'>
                        <h4> {item.name} Account - {Number(item.total).toFixed(2)} </h4>
                    </div>
                    <table className='table custom max10 table-white vc sm-table'>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Type</th>
                                <th scope="col">Description</th>
                                <th scope="col">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {item?.payments.map((a, id) => (
                                <tr className={'tr ' + id} key={"num" + id}>
                                    <td>{formatDate(a.date)}</td>
                                    <td>{a.name}</td>
                                    <td>{a.description}</td>
                                    <td className={a.type}>{Number(a.amount).toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}

            <br />

            {Expense && (
                <div className='popup'>
                    <div className='child wide'>
                        <header className='flex'>
                            <h4>Add New Expense</h4>
                            <span onClick={() => { setExpense(false); }}><i className="fa-solid fa-xmark"></i></span>
                        </header>
                        <section>
                            <form ref={ExpenseRef}>
                                <input name='property' type='hidden' value={mainState.selected_Property} />
                                <label>Name <Select name='name' required options={expenseList} defaultValue={expenseList[0]} /> </label>
                                <br />
                                <label>
                                    Amount: <input className='form-control' type='number' onWheel={(e) => e.target.blur()} name='amount' placeholder='amount' />
                                </label>
                                <br />
                                Description: <textarea name='description' className='form-control' placeholder='POS Description'></textarea>
                                <label className='hide'>
                                    date
                                    <input name='date' className='form-control' type='date' readOnly defaultValue={new Date().toISOString().substr(0, 10)} />
                                </label>

                                <label>
                                    Attachment
                                    <input type="file" name="image" className="form-control" />
                                </label>
                                <br />
                                <input name='type' type='hidden' defaultValue='outgoing' />
                                <input name='paymentMethod' type='hidden' defaultValue={mainState?.user?.username} />
                            </form>
                        </section>
                        <footer>
                            <p className='btn btn-primary' onClick={saveExpense}> Add </p>
                        </footer>
                    </div>
                </div>
            )}
        </>
    );
}

export default Cash;
